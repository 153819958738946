.hero {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display:flex;
    flex-direction: column;
    row-gap: 1.5rem;
}

.hero-img {
    transform: scale(0.975);
}

.title {
    font-size: 1.571rem;
    font-weight: 700;
    line-height: 2.25rem;
    text-align: center;
    margin-top: 0.75rem;
    color: var(--color-dark-blue);
}

.desc {
    font-size: 0.9225rem;
    line-height: 1.325rem;
    color: var(--color-dark-blue);
    text-align: center;
    padding-right: 0.375rem;
    margin-top: 1.5rem;
}

.fast-form {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    margin-top: 1.5rem;
}

.textfield-container {
    display: flex;
    flex-direction: column;
    padding-left: 0.375rem;
    margin-top: 0.5rem;

}
.submit-btn {
    margin-left: 0.25rem;
    margin-right: 0.125rem;
}

@media (min-width: 768px) {
     .hero {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 0;
    }
    .hero-img {
       order: 1;
        /*  overflow: hidden; */
    }
}

@media (min-width: 1024px) {
   
    .hero-img {
    }

    .title {
        padding-top: 4.625rem;
        font-size: 2.525rem;
        line-height: 3.625rem;
        text-align: left;
        font-family: 'Raleway', sans-serif;
    }

    .desc {
        text-align: left;
        font-size: 1.2rem;
        line-height: 1.375rem;
        margin-top: 1.25rem;
        padding-right: 2.5rem;
    }

    .fast-form {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
        margin-top: 1.5rem;
    }

    .textfield-container {
        margin-top: 0.25rem;
        padding-left: 0;
    }

    .textfield-container input[type="text"] {
        padding: 0.75rem 1.25rem;
        width: 20rem;
    }

    .submit-btn {
        margin-left: 0;
        align-self: start;
        justify-self: start;
        margin-top: 0.25rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        width: 12.5rem;
    }
}