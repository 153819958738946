.button {
  background-color: var(--color-bright-blue);
  color: #fff;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 0.25rem;
  font-family: var(--font-Raleway);
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.2);
  transition: all 0.7s ease;
}

.button:hover {
  background-color: var(--color-light-blue);
}
