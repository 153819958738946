:root {
  /* fonts */
  --font-open-sans: "Open Sans", sans-serif;
  --font-Raleway: "Raleway", sans-serif;

  /* dark blue */
  --color-dark-blue: #070439;
  --color-desaturated-blue: #585989;
  --color-bright-blue: #3065f8;
  --color-light-blue: rgb(117, 153, 247);
  --color-moderate-cyan: #3c9f8f;
  --color-light-cyan: rgb(146, 218, 204);
  --color-light-grayish-blue: #f6f6fe;
  --color-light-gray: #bfbfbf;
  --color-red: rgb(190, 48, 48);
  --color-white: #fff;
}
