.footer {
  background-color: var(--color-dark-blue);
  padding: 4rem 2.5rem;
  color: var(--color-light-grayish-blue);
}

.section {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 2.5rem;
}

.first {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.first > div {
  display: flex;
  gap: 1rem;
}

.menu > ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.menu > ul > li a:hover {
  transition: all 0.7s ease;
  color: var(--color-light-blue);
}

.last {
  display: flex;
  justify-content: center;
  gap: 0.75rem;
}

.social-icon {
  border: 1px solid var(--color-light-grayish-blue);
  border-radius: 100%;
  padding: 0.4rem;
  transition: all 0.7s ease;
}

.social-icon:hover {
  border-color: var(--color-light-blue);
}

.social-icon > svg {
  width: 0.8rem;
  height: 0.8rem;
  transition: all 0.7s ease;
}

.social-icon:hover > svg {
  color: var(--color-light-blue);
}

@media (min-width: 768px) {
  .footer {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(1, minmax(8rem, auto));
    margin-top: 1.5rem;
    justify-items: start;
    gap: 1.5rem;
  }

  .first {
    grid-row: 1 / -1;
    grid-column: 1 / 2;
  }

  .menu {
    grid-row: 1 / -1;
  }

  .last {
    grid-row: 1 / -1;
    grid-column: 1 / 2;
    place-self: end;
    margin-right: auto;
    align-items: start;
  }

  .social-icon > svg {
    width: 0.9rem;
    height: 0.9rem;
  }
}

@media (min-width: 1440px) {
  .footer {
    padding: 7rem 3.7rem 5rem;
  }

  .section {
    grid-template-columns: repeat(4, auto);
  }

  .last {
    grid-column: 4 / -1;
    place-self: start;
  }
}
