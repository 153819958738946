.header {
    display: flex;
    padding-top: 1.5rem;
}

.logo {
    width: 5rem;
}

@media (min-width: 768px) {
    .header {
        padding-top: 4rem;
    }
    .logo {
        width: 10.5rem;
    }
}