.blockquote {
    padding: 1.5rem;
    box-shadow: 0px 0px 5px 0px var(--color-light-gray);
    margin: 1.5rem;
}

.quote {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.65rem;
    line-height: 1.075rem;
    margin-top: 0.375rem;
}

.footer {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
}

.avatar {
    border-radius: 100%;
    width: 1.5rem;
    height: 1.5rem;
}

.info {
    display:flex;
    flex-direction: column;
}

.name {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.65rem;
    font-weight: 700;
}

.role {
    font-family: 'Open Sans', sans-serif;
    font-size: 0.375rem;
}

@media (min-width: 768px) {
    .blockquote {
        margin: 0;
        margin-top: 1.8rem;
        max-width: 350px;
        padding:1.5rem 2rem;
    }

    .quote {
        font-size: 0.85rem;
        margin-top: 0.85rem;
        line-height: 1.375rem;
    }

    
    .avatar {
        width: 2.125rem;
        height: 2.125rem;
    }

    .info {
        justify-content: end;
    }

    .name {
        font-size: 0.7rem;
        letter-spacing: 0.05rem;
    }

    .role {
        font-size: 0.55rem;
    }

}